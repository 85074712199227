* {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: sans-serif;
}

body .content {
  height: 100%;
  flex-direction: column;
  padding: 1rem;
  display: flex;
}

#editor {
  grid-gap: 1px;
  background: #000;
  border: 1px solid #000;
  flex-grow: 1;
  grid-template: "controls controls"
                 "code output" 1fr
                 / 2fr 1fr;
  display: grid;
}

#editor > * {
  background: #fff;
}

#editor #code {
  grid-area: code;
}

#editor #controls {
  grid-area: controls;
}

#editor #output {
  grid-area: output;
}

#code {
  position: relative;
}

#controls button, #controls select {
  padding: .2rem .5rem;
  font-size: 2rem;
}

#output {
  position: relative;
}

#output > div {
  width: 100%;
  height: 100%;
  padding: .3rem;
  position: absolute;
  overflow: auto;
}

/*# sourceMappingURL=index.b7671cdd.css.map */
