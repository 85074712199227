* {
  box-sizing: border-box;
}


body, html {
  font-family: sans-serif;
  margin: 0;
  height: 100%;
}


body .content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
}

#editor {
  flex-grow: 1;
  border: 1px solid black;
  display: grid;
  grid-template-areas:
    "controls controls"
    "code output";
  grid-template-rows: auto 1fr;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1px;
  background: black;

  > * { background: white; }

  #code { grid-area: code; }
  #controls { grid-area: controls; }
  #output { grid-area: output; }
}

#code {
  position: relative;
}

#controls {
  button, select {
    font-size: 2rem;
    padding: .2rem .5rem;
  }
}

#output {
  position: relative;
  > div {
    position: absolute;
    padding: .3rem;
    width: 100%;
    height: 100%;  
    overflow: auto;
  }
}
